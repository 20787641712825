export default {
  /**
   * @description: 根据部件类型，获取不同的图标
   * @param {object} item
   * @return {string}
   */
  getSensorIcon(item) {
    let iconType = '';

    switch (item.type) {
      case '3001':
        // iconType = "#icon-wendu";
        iconType = '#icon-iconset0480';
        break;

      case '3002': // 电流
        iconType = '#icon-xiebodianliu';
        break;
      case '3003': // 漏电流
        iconType = '#icon-loudian';
        break;
      case '3004': // 电压
        iconType = '#icon-dianyabiao1';
        break;
      case '3006': // 功率
        iconType = '#icon-gongshuai1';
        break;
      case '3008': // 湿度
        iconType = '#icon-shidu1';
        break;
      case '3009': // 信号强度
        iconType = '#icon-xinhao2';
        break;
      case '3100': //电能计量
        iconType = '#icon-yongdianliang';
        break;

      case '4001': // 烟感
        iconType = '#icon-yanwubaojingqi';
        break;
      case '4002': // 可燃气
        iconType = '#icon-ranqijianceqi';
        break;

      case '4004': // 燃气浓度
        iconType = '#icon-VOCnongdu';
        break;

      case '8510': //电源信息
        iconType = '#icon-dianyuan2';
        break;

      case '8511': //水浸
        iconType = '#icon-shuijin';
        break;

      case '8512': //手动报警 AZ_Button
        iconType = '#icon-shoudongbaojingqi';
        break;

      case '8513': //开门报区
        iconType = '#icon-kaimenbaojing';
        break;

      case '8514': //浪涌检测
        iconType = '#icon-fangleizhuangzhijiance1';
        break;

      case '8515': //自动灭火
        iconType = '#icon-miehuoqi2';
        break;

      case '8516': //除湿状态
        iconType = '#icon-caozuo_chushi';
        break;

      case '8517': //加热状态
        iconType = '#icon-jiareqi';
        break;

      case '8518': //自动模式
        iconType = '#icon-yibiaopan';
        break;

      case '8519': //故障报警
        iconType = '#icon-icon_yiwenkongxin';
        break;

      case '8525': // 安装状态
      case '8530': // 工作状态
        iconType = '#icon-kongxinduigou';
        break;

      case '85': //通用输入型部件
        iconType = '#icon-APIjieru';
        break;

      case '8611': // 电源切断 空开控制
        iconType = '#icon-kongkai';
        break;
      case '8612': // 警铃|声光报警器 CommAlarmBell
        iconType = '#icon-gaojing';
        break;
      case '8613': // 电磁阀
        iconType = '#icon-diancifa';
        break;
      case '8614': // 风扇
        iconType = '#icon-act004';
        break;

      case '86': // 通用输出型部件
        iconType = '#icon-APIshuchu';
        break;

      case '9107': //中继器
        iconType = '#icon-xinhao';
        break;

      default:
        iconType = '#icon-jiedianguanli';
        break;
    } // end switch

    return iconType;
  },


  /**
   * @description: 人性化时间格式
   * @param {int} timestamp
   * @return {string}
   */
  humanTime(timestamp) {
    var mistiming = Math.round(new Date() / 1000) - timestamp;
    var postfix = mistiming > 0 ? '前' : '后';
    mistiming = Math.abs(mistiming);
    var arrr = ['年', '个月', '星期', '天', '小时', '分钟', '秒'];
    var arrn = [31536000, 2592000, 604800, 86400, 3600, 60, 1];
    for (var i = 0; i < 7; i++) {
      var inm = Math.floor(mistiming / arrn[i]);
      if (inm != 0) {
        return inm + arrr[i] + postfix;
      }
    }
    return '刚刚';
  },
  /**
   * @description: 获取 cookie 值, 主要是为了上传模块
   * @return string
   */
  getCookie: (cname) => {
    let value = '';
    var name = cname + "=";
    var ca = document.cookie.split(';');

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i].trim();
      if (c.indexOf(name) == 0) value = c.substring(name.length, c.length);
    }

    return decodeURIComponent(value);
  },
  /**
   * @description: API请求签名，用于上传文件时加上授权
   * @return {object}
   */
  addAuthorization: () => {
    const token = JSON.parse(localStorage.getItem('token'));
    return {
      Authorization: 'Bearer ' + token,
    };
  },

  /**
   * @description: 用于 ant-d select框的search功能
   * @return {string}
   */
  filterOption: (input, option) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  },

  /**
   * @description: 请理对象或数组里的无效数据
   * @return {object}
   */
  cleanParams: (params) => {
    const deleted = [NaN, null, undefined, ''];
    for (let key in params) {
      if (deleted.includes(params[key])) delete params[key];
    }
    return params;
  },
  /**
   * @description: 清理html代码
   * @param {string} value
   * @return {string}
   */
  stripTags(value) {
    return value.replace(/<.*?>/g, ' ');
  }
};

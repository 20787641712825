/*
 * @Author: Tiger
 * @Developer: 浙江荷鼎网络科技有限公司
 * @Date: 2023-01-29 10:21:14
 * @LastEditors: freehzw hzw2235@aliyun.com
 * @LastEditTime: 2024-05-12 17:41:10
 * @Description: vue spa
 */
import './bootstrap';
import '../css/register.scss';

import { createApp } from 'vue';
import App from "./App.vue";

/* Init Pinia */
import { createPinia } from "pinia";
const pinia = createPinia();

import router from "./router";
import iotjs from "./utilities/iot";
import api from './utilities/api';


import { message } from "ant-design-vue";
import 'ant-design-vue/es/message/style/css';
import 'ant-design-vue/es/modal/style/css';

import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");

import socket from './socketio.js'; // socketio.js

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
// perfect-scrollbar css
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
// 设备canvas拓扑流程图组件
import VueKonva from 'vue-konva';

const app = createApp(App);

app.use(router)
  .use(Toast, { maxToasts: 4, icon: false, })
  .use(pinia)
  .use(VueKonva);

//antd-v 全局配置
message.config({
  maxCount: 2, //最多同时显示2条
});

app.config.globalProperties.$iotjs = iotjs;
app.config.globalProperties.$api = api;
app.config.globalProperties.$message = message;
app.config.globalProperties.$dayjs = dayjs;
app.config.globalProperties.$socket = socket;

app.mount('#app');

import axios from 'axios';
import { get } from 'lodash-es';
import { errorLog, errorCreate } from './tools';
import $router from '@/router';
import NProgress from 'nprogress'; // 引入nprogress插件
import 'nprogress/nprogress.css';  // 这个nprogress样式必须引入
import { message } from "ant-design-vue";

function request(config) {
  const token = JSON.parse(localStorage.getItem('token'));

  // 创建axios的实例
  const instance = axios.create({
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': get(config, 'headers.Content-Type', 'application/json')
    },
    timeout: 60000,
    // baseURL: import.env.meta.VITE_API_URL,
    baseURL: '/api/v2/',
    data: {}
  });

  // 请求拦截器配置
  instance.interceptors.request.use(
    config => {
      return config;
    },
    error => {
      // 发送失败
      console.log(error);
      return Promise.reject(error);
    }
  );

  // 响应拦截器配置
  instance.interceptors.response.use(
    response => {
      // 设置加载进度条(结束..)
      NProgress.done();
      // dataAxios 是 axios 返回数据中的 data
      const dataAxios = response.data;
      // 这个状态码是和后端约定的
      const { code } = dataAxios;
      // 根据 code 进行判断
      if (code === undefined) {
        // 如果没有 code 代表这不是项目后端开发的接口或可能是用户登录
        return dataAxios;
      } else {
        switch (code) {
          case 0:
            if (dataAxios.message) {
              const status = dataAxios?.status;
              if (status == 'warning') message.warning(dataAxios.message);
              else if (status == 'error') message.error(dataAxios.message);
              else message.success(dataAxios.message);
            }
            return dataAxios.data;

          default:
            // 不是正确的 code
            errorCreate(`${dataAxios.msg}: ${response.config.url}`);
            break;
        }
      }
    },
    error => {
      NProgress.done();
      const status = get(error, 'response.status');

      switch (status) {
        case 400:
          error.message = `请求错误: ${error.response.data.message}`;
          break;
        case 401:
          error.message = `${error.response.data.message}` ?? '未授权，请登录';
          break;
        case 403:
          error.message = '拒绝访问';
          break;
        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`;
          break;
        case 408:
          error.message = '请求超时';
          break;
        case 422: //校验出错
          error.message = `表单出错: ${error.response.data.message}`;
          break;
        case 500:
          error.message = '服务器内部错误';
          break;
        case 501:
          error.message = '服务未实现';
          break;
        case 502:
          error.message = '网关错误';
          break;
        case 503:
          error.message = '服务不可用';
          break;
        case 504:
          error.message = '网关超时';
          break;
        case 505:
          error.message = 'HTTP版本不受支持';
          break;
        default:
          break;
      }

      errorLog(error);

      if (status === 401) $router.push({ path: '/login' });//返回登录页
      return Promise.reject(error);
    }
  );

  return instance(config);
}

export default request;

import { defineStore } from "pinia";
import { get } from '@/utilities/api';

export const useUserStore = defineStore("user", {
  state: () => ({
    user: {},
    theme: 'light',
    adminMenus: [],
    asyncRoutes: false, //动态路由同步标志
    mapOptions: {}, //电子地图选项
    deviceTopoOptions: {} //设备拓扑选项
  }),
  getters: {
    getUser: (state) => {
      state.user = JSON.parse(localStorage.getItem('user'));
      return state.user;
    },
    // 检查用户是否有某个权限
    userCan: (state) => {
      return (permission) => state.user.permissions.includes(permission);
    },
    // 从 localStorage 获取，没有的话，返回空对象
    getMapOptions: (state) => {
      state.mapOptions = JSON.parse(localStorage.getItem('mapOptions'));
      return state.mapOptions || {};
    },
    // 自定义平台标题
    getAppName: (state) => {
      let appName = import.meta.env.VITE_APP_NAME ?? '荷鼎消防预警平台';
      const selfPlatformTitle = state.user.user_meta?.platform_title;
      if (selfPlatformTitle) appName = selfPlatformTitle;
      return appName;
    },
    getDeviceTopoOptions: (state) => {
      state.deviceTopoOptions = JSON.parse(localStorage.getItem('deviceTopoOptions'));
      return state.deviceTopoOptions || {};
    },
  },
  actions: {
    setUser(payload) {
      this.user = payload;
      localStorage.setItem('user', JSON.stringify(payload));
    },
    async getAdminMenus() {
      this.adminMenus = await get('/appdata', { data_type: 'admin-menus' });
    },
    // 设置地图选项，保存到localStorage
    setMapOptions(payload) {
      this.mapOptions = payload;
      localStorage.setItem('mapOptions', JSON.stringify(payload));
    },
    // 设置设备拓扑选项，保存到localStorage
    setDeviceTopoOptions(payload) {
      this.deviceTopoOptions = payload;
      localStorage.setItem('deviceTopoOptions', JSON.stringify(payload));
    }
  },
});

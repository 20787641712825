/**
 * @description: 常用的api请求接口
 * @Date: 2023-02-17 23:33:58
 */
import request from './request';

// 清理过滤请求参数中的空值
const cleanParams = (params = {}) => {
  for (let key in params) {
    if (params[key] === '' || params[key] === null || params[key] === undefined)
      delete params[key];
  }
  return params;
};

export const get = (url, params = {}) => {
  return request({
    method: 'GET',
    url: url,
    params: cleanParams(params)
  });
};

export const post = (url, data = {}) => {
  return request({
    method: 'POST',
    url: url,
    data: data
  });
};

export const patch = (url, data = {}) => {
  return request({
    method: 'PATCH',
    url: url,
    data: data
  });
};

export const detroy = (url, params = {}) => {
  return request({
    method: 'DELETE',
    url: url,
    params: params
  });
};
//下载blob时
export const download = (url, params = {}) => {
  return request({
    method: 'GET',
    url: url,
    params: params,
    responseType: 'blob',
  });
};

export default {
  get: (url, param = {}) => get(url, param),
  post: (url, data = {}) => post(url, data),
  store: (url, data = {}) => post(url, data),
  update: (url, data = {}) => patch(url, data),
  delete: (url, param = {}) => detroy(url, param),
  download: (url, param = {}) => download(url, param), //下载pdf用

  devices: {
    index(params = {}) {
      const url = `/devices`;
      return get(url, params);
    },
    show(id) {
      return get(`/devices/${id}`);
    },
    store(data = {}) {
      return post('/devices', data);
    },
    update(id, data = {}) {
      return patch(`/devices/${id}`, data);
    },
    delete(id) {
      return detroy(`/devices/${id}`);
    },
    remote(id, data = {}) { //指令下发
      return post(`/devices/${id}/remote`, data);
    }
  },

  //获取平台综合统计信息
  getAppStats() {
    return get(`/stats/appstats`);
  },
  /**
   * 获取报警类型定义
   */
  getAlarmTypes() {
    return get('/config/?tag=alarm-types');
  },
  /**
   * @description: 获取项目列表 options 数据
   */
  getProjectOptions() {
    const params = { tag: 'projectOptions' };
    return get('/options', params);
  },
  /**
   * @description: 获取项目下设备组列表 options 数据
   * @param {int} project_id
   */
  getProjectGroupOptions(project_id) {
    const params = { tag: 'projectGroupOptions', project_id: project_id };
    return get('/options', params);
  },
  /**
   * @description: 获取报警纪录统计状态
   */
  getAlarmStats(params = {}) {
    return get('/stats/alarms', params);
  },
  /**
   * @description: 获取报警事件统计状态
   */
  getAlarmEventStats(params = {}) {
    return get('/stats/alarm-events', params);
  },
  //获取设备统计状态
  getDeviceStatis(params = {}) {
    return get('/stats/devices', params);
  },

};

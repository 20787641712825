import io from "socket.io-client";

// websocket监听网址 const URL = "http://127.0.0.1:9003" #本地测试
const URL = import.meta.env.VITE_APP_WEBSOCKET_URL;

const socket = io(URL, {
  debug: true,
  autoConnect: false
});

export default socket;

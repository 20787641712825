export default [
  // {
  //   path: "statics",
  //   name: "monitor.statics",
  //   meta: { title: '统计面板', keepAlive: true },
  //   component: () => import('@/pages/Monitor/Statics/Dashboard.vue'),
  // },
  {
    path: "map",
    name: "monitor.map",
    meta: { title: '电子地图', keepAlive: true },
    component: () => import('@/pages/Map/Index.vue'),
  },
  {
    path: "panel",
    name: "monitor.panel",
    meta: { title: "实时监控", keepAlive: false },
    component: () => import('@/pages/Monitor/Panel.vue'),
    children: [
      {
        path: 'project/:project_id/group/:group_id/device-status/',
        name: 'monitor.device-status',
        meta: { title: '设备状态数据', keepAlive: false, tab: 'device-status' },
        component: () => import('@/pages/Monitor/DeviceStatus/Index.vue'),
      },
      {
        path: 'project/:project_id/group/:group_id/camera-panel/',
        name: 'monitor.camera-panel',
        meta: { title: '视频监控', keepAlive: true, tab: 'camera-panel' },
        component: () => import('@/pages/Monitor/Cameras/Index.vue'),
      },
      {
        path: 'project/:project_id/group/:group_id/device-status-charts/',
        name: 'monitor.device-status-charts',
        meta: { title: '设备历史数据图表', keepAlive: true, tab: 'device-status-charts' },
        component: () => import('@/pages/Monitor/DeviceStatusChart/Index.vue'),
      },
      {
        path: 'project/:project_id/group/:group_id/alarm-events/',
        name: 'monitor.alarm-events',
        meta: { title: '设备隐患(报警事件)', keepAlive: true, tab: 'alarm-events' },
        component: () => import('@/pages/Monitor/AlarmEvents/Index.vue'),
      },
      {
        path: 'project/:project_id/group/:group_id/alarms/',
        name: 'monitor.alarms',
        meta: { title: '设备报警明细', keepAlive: true, tab: 'alarms' },
        component: () => import('@/pages/Monitor/Alarms/Index.vue'),
      },
      {
        path: 'project/:project_id/group/:group_id/contacters/',
        name: 'monitor.contacters',
        meta: { title: '设备组联系人', keepAlive: true, tab: 'contacters' },
        component: () => import('@/pages/Monitor/Contacters/Index.vue'),
      },
      {
        path: 'project/:project_id/group/:group_id/iotlogs/',
        name: 'monitor.iotlogs',
        meta: { title: '工程日志', keepAlive: true, tab: 'iotlogs' },
        component: () => import('@/pages/Monitor/IotLogs/Index.vue'),
      },
      {
        path: 'project/:project_id/group/:group_id/map/',
        name: 'monitor.group-map',
        meta: { title: '地图位置', keepAlive: true, tab: 'group-map' },
        component: () => import('@/pages/Monitor/Map/Index.vue'),
      },
      {
        path: 'project/:project_id/group/:group_id/online-times/',
        name: 'monitor.online-times',
        meta: { title: '在线纪录', keepAlive: true, tab: 'online-times' },
        component: () => import('@/pages/Monitor/DeviceOnlineTimes/Index.vue'),
      },
    ]
  },
];

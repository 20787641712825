import { createRouter, createWebHashHistory } from "vue-router";
import monitorRoutes from "./monitor.route";
import { useUserStore } from "@/store/user";
import { computed } from "vue";

const appName = computed(() => useUserStore().getAppName); // 用户自定义平台标题

/**
 * @description: 静态路由定义
 */
const routes = [
  {
    path: '/',
    // redirect: { name: 'admin.dashboard' },
    redirect: { name: 'monitor.panel' },
    component: () => import("@/layouts/GuestLayout.vue"),
    children: [
      {
        path: 'login',
        name: 'login',
        meta: { title: "用户登录", auth: false },
        component: () => import('@/pages/Auth/Login.vue'),
      },
      {
        path: 'loginby', //免登录
        name: 'loginby',
        meta: { title: "Login By Token", auth: false },
        component: () => import('@/pages/Auth/LoginByToken.vue'),
      },
    ]
  },
  {
    path: '/wechat-location',
    name: 'wechat.location',
    beforeEnter: auth,
    meta: { title: "微信定位" },
    component: () => import('@/pages/Wechat/Location.vue'),
  },
  {
    path: "/monitor",
    name: 'monitor',
    beforeEnter: auth,
    redirect: { name: 'monitor.panel' },
    component: () => import("@/layouts/MonitorLayout.vue"),
    children: monitorRoutes
  },
  {
    path: '/admin',
    name: 'admin',
    beforeEnter: auth,
    redirect: { name: 'admin.dashboard' },
    component: () => import("@/layouts/AdminLayout.vue"),
    children: [
      {
        path: "dashboard",
        name: "admin.dashboard",
        component: () => import('@/pages/Dashboard/Index.vue'),
        meta: { title: "统计面板", keepAlive: false },
      },
      {
        path: "about-us",
        name: "admin.about-us",
        component: () => import('@/pages/About/AboutUs.vue'),
        meta: { title: "关于我们", keepAlive: true },
      },
      {
        path: "privacy-policy",
        name: "admin.privacy-policy",
        component: () => import('@/pages/About/PrivacyPolicy.vue'),
        meta: { title: "隐私政策", keepAlive: true },
      },
    ]
  },
  {
    path: '/404',
    name: '404',
    meta: { title: "Error" },
    component: () => import("@/pages/Errors/Index.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 };
  },
});


/**
 * @description: 从后台获取的导航菜单定义路由
 * @Date: 2023-04-28 16:12:47
 * @param {*} menus
 */
function setMenuRoutes(menus) {
  const routes = [];

  let modules = import.meta.glob('../pages/**/*.vue');

  for (let menu of menus) {
    // 外部链接不用加入
    if (menu.path && menu.path.indexOf('http') === 0) continue;

    const route = {
      path: menu.path ? menu.path : '',
      name: menu.name,
      meta: { title: menu.title, keepAlive: menu.keep_alive },
    };

    // if (menu.component) route.component = () => import( /* @vite-ignore */ '../' + menu.component + '.vue');
    if (menu.component) route.component = modules[`../pages/${menu.component}.vue`];

    if (!_.isEmpty(menu.children)) route.children = setMenuRoutes(menu.children);

    routes.push(route);
  }

  return routes;
}


/**
 * @description: 添加动态路由
 * @Date: 2023-04-28 16:10:35
 */
router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title || '';
  if (appName.value) document.title += ` - ${appName.value}`;
  else document.title += ' - 荷鼎网络';

  if (to.name == 'login' || to.name == 'loginby' || to.name == "user.location") next();
  else {
    const userStore = useUserStore();

    if (userStore.asyncRoutes) next();
    else {
      // 从后台获取导航菜单定义
      await userStore.getAdminMenus();
      const menuRoutes = setMenuRoutes(userStore.adminMenus);
      // 添加动态路由
      menuRoutes.forEach((route) => router.addRoute('admin', route));
      // 404跳转
      router.addRoute({ path: '/:pathMatch(.*)', redirect: '/404' });
      userStore.asyncRoutes = true; // 设置路由已同步后的状态

      next({ ...to, replace: true });     //路由进行重定向放行
    }
  }

  // console.log('all router', router.getRoutes())
});


/**
 * @description: beforeEnter 鉴权
 * @Date: 2023-04-27 23:46:03
 */
async function auth(to, from, next) {
  if (JSON.parse(localStorage.getItem('loggedIn'))) {
    next();
  } else {
    next({ path: '/login', query: { redirect: to.path } });
  }

}

export default router;
